import React, { useState, useEffect, useRef } from 'react';
import { Button, message, Affix, Row, Table, Modal } from 'antd';
import { useNavigate } from "react-router-dom";

import { EditOutlined, DeleteOutlined, ExclamationCircleFilled } from '@ant-design/icons';

import { PageTitle, Loading } from 'components';
import { api } from 'api';

import { getErrorMessage } from 'helpers/feedbackMessageHelper';
import AddPlayer from './components/AddPlayer';

import useAdmin from "hooks/useAdmin";

import { PlayerPcIdLabel, AdminBreadcrumbs } from './components';
import { EditControls, EditableCell, EditableRow, GetColumnSearchProps } from 'components/FccTable/components';

function EditPlayers() {

    const [modal, contextHolder] = Modal.useModal();

    const { adminData } = useAdmin();
    const navigate = useNavigate();
    
    const [isUpdating, setIsUpdating] = useState(false);
    const [playerData, setPlayerData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [addPlayerModalIsVisible, setAddPlayerModalIsVisible] = useState(false);
    const [autocompleteData, setAutocompleteData] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);

    const searchInput = useRef(null);

    const savePlayer = async (playerId, editedRowData) => {

        setIsUpdating(true);

        const editedDataToUpdate = [...playerData];
        const index = editedDataToUpdate.findIndex(item => playerId === item.id);  

        if (index > -1) {

            const item = editedDataToUpdate[index];

            // has the data actually changes
            if (!(item && JSON.stringify(item) === JSON.stringify(editedRowData))) {
                const editedRow = { ...item, ...editedRowData };

                editedDataToUpdate.splice(index, 1, editedRow);
                setPlayerData(editedDataToUpdate);

                let updatedSelectedRowKeys = selectedRowKeys;

                if (updatedSelectedRowKeys.indexOf(playerId) === -1) {
                    updatedSelectedRowKeys.push(playerId);
                }
                
                setSelectedRowKeys(updatedSelectedRowKeys);
            }
        }
        
        setIsUpdating(false);
    }

    const savePendingEdits = async () => {

        setIsUpdating(true);

        if (!selectedRowKeys.length) {
            message.error("No changes made");
            setIsUpdating(false);
            return;
        }

        const playersToSave = playerData.filter(player => selectedRowKeys.includes(player.id));

        try {
            await api.player.updateMultiplePlayers(playersToSave);

            setSelectedRowKeys([]);

            message.success("Players successfully saved");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    const deletePlayer = async (recordId) => {

        setIsUpdating(true);

        try {
            await api.player.deletePlayer(recordId);

            const deletedDataToUpdate = playerData;

            const index = deletedDataToUpdate.findIndex(item => recordId === item.id);

            if (index > -1) {
                deletedDataToUpdate.splice(index, 1);
                setPlayerData(deletedDataToUpdate);
            }            

            // remove from selected keys
            if (selectedRowKeys.findIndex(player => recordId === player.id) > -1) {
                const selectedRowKeysToUpdate = selectedRowKeys;
                const selectedRowKeyIndex = selectedRowKeysToUpdate.findIndex(player => recordId === player.id);

                selectedRowKeysToUpdate.splice(selectedRowKeyIndex, 1);
                setSelectedRowKeys(selectedRowKeysToUpdate);
            }

            message.success("Player successfully deleted");
        } catch (error) {
            const errorMessage = error.response ? error.response.data.error : 'generic';
            message.error(getErrorMessage(errorMessage));
        }

        setIsUpdating(false);
    }

    const handleDeleteModal = async (playerId) => {
        modal.confirm({
            title: 'Are you sure you want to delete this player?',
            icon: <ExclamationCircleFilled />,
            onOk: () => deletePlayer(playerId),
            okType: 'danger'
        });
    }

    const columns = [
        {
            title: 'First Name',
            dataIndex: 'firstName',
            sorter: (a, b) => a.firstName.localeCompare(b.firstName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            key: 'firstName',
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: 'firstName',
                required: true,
                editable: true,
                handleSave: savePlayer
            }),
            ...GetColumnSearchProps({
                dataIndex: 'firstName',
                label: 'First Name',
                searchInput: searchInput,
                handleReset: (clearFilters) => handleReset(clearFilters),
            })
        },
        {
            title: 'Last Name',
            dataIndex: 'lastName',
            sorter: (a, b) => a.lastName.localeCompare(b.lastName),
            sortDirections: ['ascend', 'descend', 'ascend'],
            key: 'lastName',
            onCell: (record) => ({
                record,
                inputType: 'text',
                dataIndex: 'lastName',
                required: true,
                editable: true,
                handleSave: savePlayer
            }),
            ...GetColumnSearchProps({
                dataIndex: 'lastName',
                label: 'Last Name',
                searchInput: searchInput,
                handleReset: (clearFilters) => handleReset(clearFilters),
            })
        },
        {
            title: 'Position',
            dataIndex: 'positionName',
            key: 'position',
            filters: adminData.getPositions().map(position => {
                return {
                    text: `${position.positionName}s`,
                    value: position.positionName
                }
            }),
            onFilter: (value, record) => record.positionName.indexOf(value) === 0,
            onCell: (record) => ({
                record,
                inputType: 'select',
                dataIndex: 'positionName',
                required: true,
                editable: adminData.seasonHasNotStarted(),
                handleSave: savePlayer
            }),
        },
        {
            title: `Base Value (${adminData.currency()}m)`,
            dataIndex: 'value',
            sorter: (a, b) => a.value - b.value,
            sortDirections: ['ascend', 'descend', 'ascend'],
            key: 'value',
            onCell: (record) => ({
                record,
                inputType: 'number',
                dataIndex: 'value',
                required: true,
                editable: adminData.seasonHasNotStarted(),
                handleSave: savePlayer
            }),
        },
        {
            title: 'Play-cricket ID',
            dataIndex: 'pcId',
            key: 'pcId',
            onCell: (record) => ({
                record,
                inputType: 'autoComplete',
                dataIndex: 'pcId',
                required: false,
                editable: true,
                handleSave: savePlayer,
                autocompleteData: autocompleteData
            }),
            hidden: !adminData.hasAutoUpdate(),
        },
        {
            title: 'Actions',
            dataIndex: 'operation',
            render: (_, record) => (
                <EditControls 
                    isUpdating={isUpdating}
                    record={record} 
                    handleDelete={deletePlayer}
                    additionalAction={{
                        title: "Edit Player match data",
                        onClick: () => navigate(`/admin/edit-player/${record.id}`),
                        icon: <EditOutlined />,
                    }}
                    additionalActions={[
                        {
                            title: 'Edit Player Data',
                            label: 'Edit Player Data',
                            onClick: () => navigate(`/admin/edit-player/${record.id}`),
                            icon: <EditOutlined />
                        },
                        {
                            title: 'Delete Player',
                            label: 'Delete Player',
                            icon: <DeleteOutlined />,
                            onClick: () => handleDeleteModal(record.id),
                            danger: true
                        }
                    ]}
                    />
            ),
            onCell: () => ({
                editable: false
            }),
        },
    ].filter(item => !item.hidden);

    const getPlayerList = async () => {

        try {
            const results = await api.player.getPlayerList();
            const playerData = await results.json();

            const playerList = playerData[0].players.concat(
                playerData[1].players, 
                playerData[2].players, 
                playerData[3].players
            );

            setPlayerData(playerList);

            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const getUnassignedPCIds = async () => {

        if (! adminData.hasAutoUpdate()) {
            return;
        }

        const result = await api.admin.getPlayersToImport({ forceRefetch: false });

        let allPlayerData = [];

        result.data.map(player => {
            const playerData = {
                value: player.pcId,
                valueForSearch: player.name,
                label: <PlayerPcIdLabel playerName={player.name} playerPcId={player.pcId} />
            }

            allPlayerData = [...allPlayerData, playerData];
        });

        setAutocompleteData(allPlayerData);
    }

    const handleReset = (clearFilters) => {
        clearFilters();
    };

    useEffect(() => {
        getPlayerList();
        getUnassignedPCIds();
    }, []);

    const handleAddPlayer = async (player) => {
        const playerDataToUpdate = playerData;
        playerDataToUpdate.push(player);

        setPlayerData(playerDataToUpdate);
        setAddPlayerModalIsVisible(false);
    }

    return (
        <>
            {contextHolder}
            <PageTitle 
                title="Add/Edit Players" 
                extra={[
                    <Button key="addPlayer" type="primary" onClick={() => setAddPlayerModalIsVisible(true)}>Add Player</Button>
                ]} 
                breadcrumbs={<AdminBreadcrumbs title="Add/Edit Players" />}
                />

            {loading ? <Loading /> : (
                <>
                    <Table 
                        components={{
                            body: {
                                cell: EditableCell,
                                row: EditableRow
                            },
                        }}
                        loading={isUpdating}
                        columns={columns} 
                        dataSource={[...playerData]} 
                        rowKey={record => record.id} 
                        pagination={{ pageSize: 50, size: "small" }}
                        bordered
                        scroll={{ x: true }}
                        showSorterTooltip={false}
                        sticky
                        rowClassName={record => selectedRowKeys.includes(record.id) && "ant-table-row-selected"}
                        />
                    <div style={{marginTop: 30 }}>
                        <Affix offsetBottom={32}>
                            <Row justify="end" className="affix-submit-save affix--shadow">
                                <Button 
                                    type="primary" 
                                    onClick={savePendingEdits} 
                                    disabled={isUpdating || !selectedRowKeys.length}
                                    loading={isUpdating}
                                    >
                                    Save Changes
                                </Button>
                            </Row>
                        </Affix>
                    </div>
                </>
            )}

            <AddPlayer
                isVisible={addPlayerModalIsVisible} 
                handleConfirm={handleAddPlayer}
                handleCancel={() => setAddPlayerModalIsVisible(false)}
                />
        </>
    )
};

export default EditPlayers;