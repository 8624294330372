import axios from 'axios';
import Cookies from 'js-cookie';
import { buildEndpoint } from 'helpers/apiHelper';

const getPlayerList = async () => {
    return fetch(buildEndpoint('player/all'));
    // return axios.get(buildEndpoint('player/all'));
}

const getPlayer = async (playerId) => {
    const endpoint = buildEndpoint(`player/${playerId}`);
    return fetch(endpoint);
    // return axios.get(endpoint);
}

const editPlayer = async(id, player) => {

    const endpoint = buildEndpoint(`player/${id}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.patch(
        endpoint,
        player,
        { headers: headers }
    );
}

const deletePlayer = async(id) => {

    const endpoint = buildEndpoint(`player/${id}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.delete(
        endpoint,
        { headers: headers }
    );
}

const addPlayer = async({firstName, lastName, positionName, value, pcId}) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('player/add'),
        {
            firstName: firstName, 
            lastName: lastName,
            positionName: positionName,
            value: value,
            pcId: pcId
        },
        { headers: headers }
    );
}

const importMultiplePlayers = async(playersToImport) => {

    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint('player/add-multiple'),
        {
            players: playersToImport
        },
        { headers: headers }
    );
}

const updateMultiplePlayers = async(playersToSave) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.patch(
        buildEndpoint('player/update-multiple'),
        {
            players: playersToSave
        },
        { headers: headers }
    );
}

const editPlayerMatchData = async(playerId, gamePlayerId, matchData) => {

    const endpoint = buildEndpoint(`player/match-data/${playerId}/${gamePlayerId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.patch(
        endpoint,
        matchData,
        { headers: headers }
    );
}

const removePlayerFromMatch = async(playerId, gamePlayerId) => {

    const endpoint = buildEndpoint(`player/match-data/${playerId}/${gamePlayerId}`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.delete(
        endpoint,
        { headers: headers }
    );
}

const addPlayerToMatch = async ({matchId, playerId}) => {

    const endpoint = buildEndpoint(`match/${matchId}/add-player`);
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        endpoint,
        { playerId },
        { headers: headers }
    );
}

const getArchivePlayerStandingYears = async (playerId) => {
    return axios.get(buildEndpoint(`player/${playerId}/archive-tables`));
}

const getArchivePlayerGameweekData = async (playerId, year) => {
    return axios.get(buildEndpoint(`player/${playerId}/archive-data/${year}`));
}

const getPlayerPriceChanges = async (playerId) => {
    return axios.get(buildEndpoint(`price-changes/${playerId}`));
}

const updatePlayerPriceChanges = async (playerId, gameweek, priceChange) => {
    const token = Cookies.get('__kopek');

    if (!token) {
        throw new Error('No token'); 
    }

    const headers = { Authorization: `Bearer ${token}` };

    return axios.post(
        buildEndpoint(`price-changes/${playerId}`),
        {
            priceChange: priceChange,
            gameweek: gameweek
        },
        { headers: headers }
    );
}


export {
    getPlayerList,
    getPlayer,
    editPlayer,
    deletePlayer,
    addPlayer,
    importMultiplePlayers,
    updateMultiplePlayers,
    editPlayerMatchData,
    removePlayerFromMatch,
    addPlayerToMatch,
    getArchivePlayerStandingYears,
    getArchivePlayerGameweekData,
    getPlayerPriceChanges,
    updatePlayerPriceChanges
}