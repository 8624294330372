export default class adminHelper {

    constructor(admin) {
        this.admin = admin;
    }

    getAdmin() {
        return this.admin;
    }

    teamBudget() {
        return this.admin.teamSize === 6 ? 35 : 55;
    }

    gameweekAdvanceDay() {
        return this.admin.gameweekAdvance;
    }

    gameweekAdvanceTime() {
        return this.admin.gameweekAdvanceTime + ':00';
    }

    currency() {
        return this.admin.nonUk ? "$" : "£";
    }

    transferDeadline() {

        const options = {
            dateStyle: "long",
            timeStyle: "short",
        };

        if (this.admin.gameweek === 0) {
            const seasonStart = new Date(
                `${this.admin.seasonStart} ${this.admin.gameweekAdvanceTime}:00` 
            );

            return seasonStart.toLocaleString('en-GB', options);
        }

        const daysOfWeek = {"Sunday": 0, "Monday": 1, "Tuesday": 2, "Wednesday": 3, "Thursday": 4, "Friday": 5, "Saturday": 6};
        const now = new Date();
        const result = new Date(
            now.getFullYear(),
            now.getMonth(),
            now.getDate() + (7 + daysOfWeek[this.admin.gameweekAdvance] - now.getDay()) % 7,
            this.admin.gameweekAdvanceTime
        );

        if (result < now) {
            result.setDate(result.getDate() + 7);
        }

        return result.toLocaleString('en-GB', options);
    }

    positionLimits() {
        return {
            upperLimit: this.admin.teamSize === 6 ? 3 : 5,
            midLimit: this.admin.teamSize === 6 ? 2 : 3
        }
    }

    positionsAndLimits() {
        const positionLimits = this.positionLimits();

        if (this.admin.sport === 'cricket') {
            return `${positionLimits.upperLimit} batters, ${positionLimits.upperLimit} bowlers, ${positionLimits.midLimit} all rounders, and 1 keeper`;
        } else {
            return `${positionLimits.upperLimit} defenders, ${positionLimits.upperLimit} midfielders, ${positionLimits.midLimit} attackers, and 1 keeper`;
        }
    }

    getPositionNames() {
        let positionNames = [];

        this.admin.positions.map((position) => {
            positionNames.push(position.positionName);
        });

        return positionNames;
    }

    getPositions() {
        return this.admin.positions;
    }

    getClubName() {
        return this.admin.clubName;
    }

    getGameweek() {
        return this.admin.gameweek;
    }

    getHomePageText() {
        return this.admin.introText;
    }

    getColor() {
        return this.admin.color;
    }

    getPcSubdomain() {
        return this.admin.pcSubdomain;
    }

    hasAutoUpdate() {
        return this.admin.autoUpdate;
    }

    doesNotHaveAutoUpdate() {
        return ! this.hasAutoUpdate();
    }

    hasEntryFeeCollection() {
        return this.admin.entryFeeCollection && this.admin.entryFeeCollection > 0;
    }

    getEntryFee() {
        return this.admin.entryFeeCollection;
    }

    getLogo() {
        return this.admin.logo ? this.admin.logo : null;
    }

    getSport()
    {
        return this.admin.sport;
    }

    sportIsCricket() {
        return this.admin.sport === 'cricket';
    }

    seasonHasStarted()
    {
        return this.admin.gameweek >= 1;
    }

    seasonHasNotStarted() {
        return !this.seasonHasStarted();
    }

    transferWindowIsOpen() {
        return this.admin.transferWindow;
    }

    canEdit() {
        return this.admin.canEdit;
    }

    getTeamSize() {
        return this.admin.teamSize;
    }

    getTransferDeduct() {
        return this.admin.transferDeduct;
    }

    getSeasonStartDate() {
        return this.admin.seasonStart;
    }

    seasonHasEnded() {
        return this.admin.seasonEnded === true;
    }
}