import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Form, Select, InputNumber, Modal, message } from 'antd';

import { api } from 'api';
import { getErrorMessage } from 'helpers/feedbackMessageHelper';

import StrategyManager from '../strategies/StrategyManager';

import useAdmin from "hooks/useAdmin";

const AddPlayer = ({isVisible, handleConfirm, handleCancel}) => {

    const { adminData } = useAdmin();

    const matchDataStrategyManager = new StrategyManager();
    const matchData = matchDataStrategyManager.getMatchDataStrategy(adminData.getSport(), adminData.hasAutoUpdate());

    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);

    const submitValues = async () => {
        form
            .validateFields()
            .then( async (values) => {

                setLoading(true);

                const { firstName, lastName, value, positionName, pcId } = values;

                try {
                    const response = await api.player.addPlayer({
                        firstName, 
                        lastName,
                        value, 
                        positionName, 
                        pcId
                    });

                    const newPlayer = response.data;
            
                    message.success("Player successfully added");

                    form.resetFields();
                    handleConfirm(newPlayer);
                    setLoading(false);
                } catch (error) {
                    const errorMessage = error.response ? error.response.data.error : 'generic';
                    message.error(getErrorMessage(errorMessage));

                    setLoading(false);
                }
                
            })
            .catch((info) => {
                console.log('Validate Failed:', info);
            });
    }
   

    return (
        <Modal 
            title="Add Player"
            open={isVisible}
            onCancel={handleCancel}
            okText="Submit"
            cancelText="Close"
            footer={[
                <Button
                    key="cancel"
                    disabled={loading}
                    onClick={handleCancel}
                    >
                    Cancel
                </Button>,
                <Button 
                    key="submit" 
                    type="primary" 
                    loading={loading} 
                    onClick={submitValues}
                    >
                    Submit
                </Button>,
            ]}
            >
            <Form layout='vertical' form={form}>
                <Form.Item 
                    name="firstName" 
                    rules={[{ required: true, message: 'Please input a first name' }]} 
                    label="First Name"
                    >
                    <Input placeholder="First Name" disabled={loading} />
                </Form.Item>
                <Form.Item 
                    name="lastName" 
                    rules={[{ required: true, message: 'Please input a last name' }]} 
                    label="Last Name"
                    >
                    <Input placeholder="Last Name" disabled={loading} />
                </Form.Item>
                <Form.Item 
                    name="value" 
                    rules={[{ required: true, message: 'Please input a value' }]} 
                    label={`Value (${adminData.currency()}m)`}
                    >
                    <InputNumber 
                        placeholder={`Value (${adminData.currency()}m)`}
                        style={{width: '100%'}}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                        step={.1} 
                        disabled={loading}
                        />
                </Form.Item>
                <Form.Item 
                    name="positionName" 
                    rules={[{ required: true, message: 'Please select a position' }]} 
                    label="Position"
                    >
                    <Select placeholder="Position" disabled={loading}>
                        {matchData.getPositions().map(position => (
                            <Select.Option value={position}>{position}</Select.Option>
                        ))}
                    </Select>
                </Form.Item>
                {adminData.sportIsCricket() && adminData.hasAutoUpdate() && 
                    <Form.Item name="pcId" label='Play Cricket ID'>
                        <InputNumber 
                            disabled={loading}
                            placeholder="Play Cricket ID"
                            style={{width: '100%'}}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                            />
                    </Form.Item>
                }
            </Form>
        </Modal>
    )
}

AddPlayer.propTypes = {
    isVisible: PropTypes.bool.isRequired,
    handleConfirm: PropTypes.func.isRequired,
    handleCancel: PropTypes.func.isRequired
}

export default AddPlayer;